import React from "react"
import "../styles/carousel.css";
import { Carousel } from 'react-responsive-carousel';
import Img from "gatsby-image"
import styles from "../styles/imageCarousel.module.css"

const ImageCarousel = ({images, path}) => {
    return (
        <Carousel
            infiniteLoop={true}
            centerMode={true}
            className={styles.carousel}
            centerSlidePercentage={90}
            showThumbs={false}
        >
            {images.map((image) => {
                return (
                    <div className={styles.imageContainer}>
                            <img className={styles.image} src={image.url}/>
                    </div>
                )
            })}
        </Carousel>
    )
}


// const ImageCarousel = ({images, path}) => {
//     return (
//         <Carousel
//             infiniteLoop={true}
//             centerMode={true}
//             className={styles.carousel}
//             centerSlidePercentage={90}
//             showThumbs={false}
//         >
//             {images.map((image) => {
//                 if(!image.localfile.childImageSharp){
//                     console.log(path, 'invalid images found')
//                     return ''
//                 }
//                 return (
//                     <div className={styles.bodyImage}>
//                         <div className={image.localfile.childImageSharp.fluid.aspectRatio < 1? styles.featureImageContainerHM : styles.featureImageContainer}>
//                             <Img fluid={image.localfile.childImageSharp.fluid} style={{maxHeight: '100%', position: 'inherit'}} imgStyle={{objectFit: 'contain'}}/>
//                         </div>
//                     </div>
//                 )
//             })}
//         </Carousel>
//     )
// }


export default ImageCarousel